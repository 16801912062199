<template>
  <div class="container">
    <div style="cursor: pointer;" @click="$router.go(-1)"><i class="arrow_back el-icon-back"></i>返回</div>
    <TitleBarBaseVue />
    <div class="box">
      <div class="activaity_set_wrap">
        <el-form ref="setForm" :model="formData" :rules="formRules" label-width="auto" size="small">
          <div class="title">基本信息</div>
          <el-form-item label="类别名称" prop="name">
            <el-input v-model="formData.name" placeholder="请输入类别名称" style="width: 300px" />
          </el-form-item>
          <el-form-item label="类别等级" prop="level">
            <el-select v-model="formData.level" placeholder="类别等级" :disabled="categoryId ? true : false">
              <el-option label="一级分类" :value="1"></el-option>
              <el-option label="二级分类" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属分类" prop="parentCategoryId" v-if="formData.level == 2">
            <el-select v-model="formData.parentCategoryId" placeholder="所属分类">
              <el-option v-for="item in firstList" :key="item.categoryId" :label="item.name" :value="item.categoryId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="隐藏状态" prop="isHide">
            <el-select v-model="formData.isHide" placeholder="状态">
              <el-option label="显示" :value="0"></el-option>
              <el-option label="隐藏" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序" prop="sortNum">
            <el-input v-model="formData.sortNum" placeholder="数字越小,优先级越高" v-Int style="width: 300px" />
          </el-form-item>
          <el-form-item label="ICON图片" prop="iconImg" class="is-required">
            <upload-place v-model="formData.iconImg" />
            <div class="share_desc">
              仅支持JPG、JPEG、BMP、PNG格式的图片（建议使用200*200px的规格）
            </div>
          </el-form-item>
          <el-form-item label="图片" prop="image" class="is-required">
            <upload-place v-model="formData.image" />
            <div class="share_desc">
              仅支持JPG、JPEG、BMP、PNG格式的图片（建议使用200*200px的规格）
            </div>
          </el-form-item>
          <el-form-item label="banner图片" prop="bannerImg" class="is-required">
            <upload-place v-model="formData.bannerImg" :styleObject="{ width: '300px', height: '100px' }" />
            <div class="share_desc">
              仅支持JPG、JPEG、BMP、PNG格式的图片（建议使用600*200px的规格）
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSave">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import uploadPlace from '@/components/upload/uploadPlace.vue'
import { categoryList, categoryUpdate } from '@/api/GoodsController'
import url from '@/utils/URL'

export default {
  data() {
    return {
      categoryId: '',

      formData: {
        categoryId: '',
        name: '',
        iconImg: '',
        image: '',
        bannerImg: '',
        isHide: 0,
        level: 1,
        sortNum: '',
        parentCategoryId: ''
      },

      firstList: [],

      formRules: {
        name: [{ required: true, type: 'string', message: '请填写类别名称', trigger: 'blur' }],
        iconImg: [{ required: true, message: '请填写排序', trigger: 'blur' }],
        image: [{ required: true, message: '请填写排序', trigger: 'blur' }],
        bannerImg: [{ required: true, message: '请填写排序', trigger: 'blur' }],
        isHide: [{ required: true, message: '请填写排序', trigger: 'blur' }],
        level: [{ required: true, message: '请填写排序', trigger: 'blur' }]
      }
    }
  },

  mounted() {
    let categoryId = this.$route.query.categoryId
    if (categoryId) {
      //编辑
      this.categoryId = categoryId
      this.formData = JSON.parse(sessionStorage.getItem('category'))
    }
    this.onCategoryList()
  },

  methods: {
    //获取一级分类
    async onCategoryList() {
      let [err, res] = await categoryList()
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取失败')
      }
      console.log(res)

      this.firstList = res.data || {}
    },

    //保存
    onSave() {
      this.$refs['setForm'].validate(async valid => {
        if (!valid) return

        if (!this.formData.iconImg.length) {
          return this.$message({
            message: '请设置ICON图片',
            type: 'warning'
          })
        }

        let param = { ...this.formData }

        if (this.formData.level == 1) {
          param.parentCategoryId = -1
        } else {
          if (!this.formData.parentCategoryId) {
            return this.$message({
              message: '请选择所属分类',
              type: 'warning'
            })
          }
        }

        let [err, res] = await categoryUpdate(param)
        if (err) {
          console.log(err)
          return this.$message.error(err.msg || '保存失败')
        }
        console.log(res)
        this.$message.success(res.msg || '保存成功')

        this.$router.go(-1)
      })
    }
  },

  components: {
    TitleBarBaseVue,
    uploadPlace
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;

  .box {
    margin: 15px 20px;
  }
  .activaity_set_wrap {
    padding: 0 15px 20px 0;
  }
  .title {
    margin: 10px 10px 15px;
  }
  .uploaders-fle {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .share_desc {
    line-height: 34px;
    font-size: 13px;
    color: #999;
  }
}
</style>
